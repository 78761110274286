/* src/components/Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
}

.header .logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.header nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

.header nav ul li {
    cursor: pointer;
}

.header nav ul li a {
    color: white;         /* Set link color to white */
    text-decoration: none; /* Remove underline from the link */
    font-size: 1rem; /* Adjust font size */
}

.header nav ul li a:hover {
    text-decoration: underline; /* Optional: Underline the link on hover for a visual cue */
}

.logo-image {
    width: 350px;
    height: 50px;
    transition: width 0.3s ease, height 0.3s ease; /* Optional: Smooth transition */
}

/* Responsive design for tablets */
@media (max-width: 768px) {
    .logo-image {
        width: 250px;
        height: 35px;
    }
    .header nav ul {
        gap: 15px;
    }
    .header nav ul li a {
        font-size: 0.9rem;
    }
}

/* Responsive design for mobile phones */
@media (max-width: 480px) {
    .logo-image {
        width: 150px;
        height: 25px;
    }
    .header nav ul {
        gap: 10px;
        flex-direction: column; /* Stack the menu vertically */
    }
    .header nav ul li a {
        font-size: 0.8rem;
    }
}
