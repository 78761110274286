.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem;
}

.contact-title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
}

.contact-title::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    transform: rotateZ(45deg);
    pointer-events: none;
    animation: shine 1.5s infinite;
}

.contact-content {
    font-size: 1.25rem;
    max-width: 800px;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.input-group label {
    font-size: 1rem;
    font-weight: bold;
}

.input-group input, .input-group textarea {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid white;
    background-color: rgba(255,255,255,0.1);
    color: white;
    border-radius: 5px;
    resize: none;
}

.submit-btn {
    text-align: center;
}

.submit-btn button {
    padding: 0.75rem 1.5rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-btn button:hover {
    background-color: rgba(255,255,255,0.8);
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}
