/* src/pages/services.css */

.uiux-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem;
}

.uiux-title {
    margin-top: -40px;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
}

.uiux-title::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    transform: rotateZ(45deg);
    pointer-events: none;
    animation: shine 1.5s infinite;
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

.service-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: white; 
    text-decoration: none; /* remove the default underline from the link */
}

.uiux-content {
    font-size: 1.25rem;
    max-width: 800px;
    text-align: center;
    margin-bottom: 2rem;
}

.uiux-content p {
    margin: 1rem 0;
}
