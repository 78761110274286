.banner {
    background-color: #eee;
    text-align: center;
    padding: 0;  /* No padding to cover the entire viewport */
    position: relative;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    /* ... other styles ... */
    background-image: url('stars.jpg'); /* replace with the relative path to your image */
    background-repeat: no-repeat; /* to avoid repetition of the image */
    background-size: cover; /* to cover the entire viewport of the .banner */
    background-position: center; /* to center the background image */
}

.banner h1 {
    font-size: 32px;
    margin-bottom: 20px;
    position: absolute;
    bottom: 10%; /* distance from the bottom of the .banner */
    left: 50%; /* move the left edge to the middle of the parent container */
    transform: translateX(-50%); /* shift it back by half its own width to center it */
    text-align: center;
    color: white; 
}

.banner p {
    font-size: 18px;
    position: absolute;
    bottom: 5%; /* distance from the bottom of the .banner */
    left: 50%; /* move the left edge to the middle of the parent container */
    transform: translateX(-50%); /* shift it back by half its own width to center it */
    text-align: center;
    color: white; 
}

.mars-container {
    position: absolute;
    top: 45%;
    left: 15%;
    transform: translateX(-50%); /* Centers horizontally */
    width: 400px; /* Match Mars' width */
    height: 400px; /* Match Mars' height */
}

.mars {
    width: 100%; /* 100% of mars-container */
    height: 100%; /* 100% of mars-container */
    border-radius: 50%;
    animation: rotatePlanet 10s infinite linear;
    position: relative; /* Relative within mars-container */
}

.mercury-container {
    position: absolute;
    top: 10%; /* Adjust to position from the top of .banner */
    left: 40%; /* Centers horizontally */
    transform: translateX(-50%); /* Centers horizontally */
    width: 400px; /* Match Mars' width */
    height: 400px; /* Match Mars' height */
}

.mercury {
    width: 100%; /* 100% of mars-container */
    height: 100%; /* 100% of mars-container */
    border-radius: 50%;
    animation: rotatePlanet 10s infinite linear;
    position: relative; /* Relative within mars-container */
}

.moon-container {
    position: absolute;
    top: 40%; /* Adjust to position from the top of .banner */
    left: 75%; /* Centers horizontally */
    transform: translateX(-50%); /* Centers horizontally */
    width: 400px; /* Match Mars' width */
    height: 400px; /* Match Mars' height */
}

.moon {
    width: 100%; /* 100% of mars-container */
    height: 100%; /* 100% of mars-container */
    border-radius: 50%;
    animation: rotatePlanet 10s infinite linear;
    position: relative; /* Relative within mars-container */
}

.planet-text {
    position: absolute;
    top: -10%; /* Adjust to position it properly above the Mars */
    left: 50%; /* Centers horizontally relative to mars-container */
    transform: translateX(-50%); /* Ensures perfect horizontal centering */
    text-align: center;
    color: white;
    text-decoration: none;
    font-size: 24px;
    z-index: 5;

    /* Shining effect additions: */
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    cursor: pointer;
}

.planet-text::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    transform: rotateZ(45deg);
    pointer-events: none;
    animation: shine 1.5s infinite;
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: -50%; }
}

.mars img, .mercury img, .moon img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

/* Adjusted size for medium screens */
@media screen and (max-width: 800px) {
    .mars {
        width: 250px;
        height: 250px;
    }
    .mercury {
        width: 250px;
        height: 250px;
    }
    .moon {
        width: 250px;
        height: 250px;
    }

}

/* Even smaller size for small screens */
@media screen and (max-width: 500px) {
    .mars {
        width: 150px;
        height: 150px;
    }
    .mercury {
        width: 150px;
        height: 150px;
    }
    .moon {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .mars-container, .mercury-container, .moon-container {
        width: 100px;
        height: 100px;
    }

    .mars, .mercury, .moon {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 500px) {
    .banner h1 {
        font-size: 12px;
        bottom: 20%; 
    }

    .banner p {
        font-size: 8px;
        bottom: 10%; 
    }
}

@media screen and (max-width: 500px) {
    .planet-text {
        font-size: 16px;
        top: -5%;
    }
}


@keyframes rotatePlanet {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}