.uiux-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem;
}

.uiux-wrapper {
    max-width: 800px;
    width: 100%;  /* This ensures it takes the full width up to the max-width */
    text-align: left;
    margin-top: 60px; 
}

.uiux-content {
    font-size: 1.25rem;
    text-align: left;  /* Adjusting text alignment to left */
    margin-bottom: 2rem;
    margin-top: 80px;   
}


.uiux-list {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 0;
    font-size: 1.15rem;
    text-align: left;
}

.uiux-title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
}

.uiux-title::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    transform: rotateZ(45deg);
    pointer-events: none;
    animation: shine 1.5s infinite;
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

.uiux-list li {
    margin-bottom: 0.5rem;
    text-align: left;
}

.ai-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align everything centrally */
}

.ai-title {
    text-align: center;
    margin-bottom: 30px;
    width: 50%;
}

.ai-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;  /* Adjusted to 60% of its parent's width */
    justify-content: center;  /* Horizontally centering the items */
    align-items: center;  /* Vertically centering the items */
    margin: 0 auto;  /* Center the entire .ai-section horizontally within its parent */
}

.ai-step {
    width: calc(40% - 10px);  /* Adjusted width of each step */
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding: 20px;
    margin: 10px;  /* Even margin around each step for consistent spacing */
}

.ai-step-icon {
    background-color: #007BFF;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.ai-step h2 {
    text-align: left;
}

.ai-step p {
    text-align: left;
}

.ai-section-title {
    font-size: 1.4rem;
    margin-bottom: 20px;
    text-align: left; /* This ensures the text alignment is to the left */
    color: #fff;  /* For a white color title */
    width: 50%;  /* Ensures it takes up the full width */
    margin-top: 60px; 
}

@media only screen and (max-width: 768px) {
    .uiux-content {
        font-size: 1rem;  /* Adjust font size for mobile view */
        margin-top: 10px; /* Adjust top margin for mobile view */
    }
    .ai-section-title{
        margin-top: 10px; 
    }
}

