.meteor {
    position: absolute;
    width: 15px;  /* Adjust this for size */
    height: 13px; /* Adjust this for size */
    background: white;
    border-radius: 50%; /* Makes it a circle */
    opacity: 0;
    transition: opacity 0.5s;
}

