body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* src/components/Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
}

.header .logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.header nav ul {
    list-style: none;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.header nav ul li {
    cursor: pointer;
}

.header nav ul li a {
    color: white;         /* Set link color to white */
    text-decoration: none; /* Remove underline from the link */
    font-size: 1rem; /* Adjust font size */
}

.header nav ul li a:hover {
    text-decoration: underline; /* Optional: Underline the link on hover for a visual cue */
}

.logo-image {
    width: 350px;
    height: 50px;
    transition: width 0.3s ease, height 0.3s ease; /* Optional: Smooth transition */
}

/* Responsive design for tablets */
@media (max-width: 768px) {
    .logo-image {
        width: 250px;
        height: 35px;
    }
    .header nav ul {
        grid-gap: 15px;
        gap: 15px;
    }
    .header nav ul li a {
        font-size: 0.9rem;
    }
}

/* Responsive design for mobile phones */
@media (max-width: 480px) {
    .logo-image {
        width: 150px;
        height: 25px;
    }
    .header nav ul {
        grid-gap: 10px;
        gap: 10px;
        flex-direction: column; /* Stack the menu vertically */
    }
    .header nav ul li a {
        font-size: 0.8rem;
    }
}

.banner {
    background-color: #eee;
    text-align: center;
    padding: 0;  /* No padding to cover the entire viewport */
    position: relative;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    /* ... other styles ... */
    background-image: url(/static/media/stars.a760918c.jpg); /* replace with the relative path to your image */
    background-repeat: no-repeat; /* to avoid repetition of the image */
    background-size: cover; /* to cover the entire viewport of the .banner */
    background-position: center; /* to center the background image */
}

.banner h1 {
    font-size: 32px;
    margin-bottom: 20px;
    position: absolute;
    bottom: 10%; /* distance from the bottom of the .banner */
    left: 50%; /* move the left edge to the middle of the parent container */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* shift it back by half its own width to center it */
    text-align: center;
    color: white; 
}

.banner p {
    font-size: 18px;
    position: absolute;
    bottom: 5%; /* distance from the bottom of the .banner */
    left: 50%; /* move the left edge to the middle of the parent container */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* shift it back by half its own width to center it */
    text-align: center;
    color: white; 
}

.mars-container {
    position: absolute;
    top: 45%;
    left: 15%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Centers horizontally */
    width: 400px; /* Match Mars' width */
    height: 400px; /* Match Mars' height */
}

.mars {
    width: 100%; /* 100% of mars-container */
    height: 100%; /* 100% of mars-container */
    border-radius: 50%;
    -webkit-animation: rotatePlanet 10s infinite linear;
            animation: rotatePlanet 10s infinite linear;
    position: relative; /* Relative within mars-container */
}

.mercury-container {
    position: absolute;
    top: 10%; /* Adjust to position from the top of .banner */
    left: 40%; /* Centers horizontally */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Centers horizontally */
    width: 400px; /* Match Mars' width */
    height: 400px; /* Match Mars' height */
}

.mercury {
    width: 100%; /* 100% of mars-container */
    height: 100%; /* 100% of mars-container */
    border-radius: 50%;
    -webkit-animation: rotatePlanet 10s infinite linear;
            animation: rotatePlanet 10s infinite linear;
    position: relative; /* Relative within mars-container */
}

.moon-container {
    position: absolute;
    top: 40%; /* Adjust to position from the top of .banner */
    left: 75%; /* Centers horizontally */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Centers horizontally */
    width: 400px; /* Match Mars' width */
    height: 400px; /* Match Mars' height */
}

.moon {
    width: 100%; /* 100% of mars-container */
    height: 100%; /* 100% of mars-container */
    border-radius: 50%;
    -webkit-animation: rotatePlanet 10s infinite linear;
            animation: rotatePlanet 10s infinite linear;
    position: relative; /* Relative within mars-container */
}

.planet-text {
    position: absolute;
    top: -10%; /* Adjust to position it properly above the Mars */
    left: 50%; /* Centers horizontally relative to mars-container */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Ensures perfect horizontal centering */
    text-align: center;
    color: white;
    text-decoration: none;
    font-size: 24px;
    z-index: 5;

    /* Shining effect additions: */
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    cursor: pointer;
}

.planet-text::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    pointer-events: none;
    -webkit-animation: shine 1.5s infinite;
            animation: shine 1.5s infinite;
}

@-webkit-keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: -50%; }
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: -50%; }
}

.mars img, .mercury img, .moon img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

/* Adjusted size for medium screens */
@media screen and (max-width: 800px) {
    .mars {
        width: 250px;
        height: 250px;
    }
    .mercury {
        width: 250px;
        height: 250px;
    }
    .moon {
        width: 250px;
        height: 250px;
    }

}

/* Even smaller size for small screens */
@media screen and (max-width: 500px) {
    .mars {
        width: 150px;
        height: 150px;
    }
    .mercury {
        width: 150px;
        height: 150px;
    }
    .moon {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .mars-container, .mercury-container, .moon-container {
        width: 100px;
        height: 100px;
    }

    .mars, .mercury, .moon {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 500px) {
    .banner h1 {
        font-size: 12px;
        bottom: 20%; 
    }

    .banner p {
        font-size: 8px;
        bottom: 10%; 
    }
}

@media screen and (max-width: 500px) {
    .planet-text {
        font-size: 16px;
        top: -5%;
    }
}


@-webkit-keyframes rotatePlanet {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


@keyframes rotatePlanet {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.meteor {
    position: absolute;
    width: 15px;  /* Adjust this for size */
    height: 13px; /* Adjust this for size */
    background: white;
    border-radius: 50%; /* Makes it a circle */
    opacity: 0;
    transition: opacity 0.5s;
}


.uiux-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem;
}

.uiux-wrapper {
    max-width: 800px;
    width: 100%;  /* This ensures it takes the full width up to the max-width */
    text-align: left;
    margin-top: 60px; 
}

.uiux-content {
    font-size: 1.25rem;
    text-align: left;  /* Adjusting text alignment to left */
    margin-bottom: 2rem;
    margin-top: 80px;   
}


.uiux-list {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 0;
    font-size: 1.15rem;
    text-align: left;
}

.uiux-title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
}

.uiux-title::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    pointer-events: none;
    -webkit-animation: shine 1.5s infinite;
            animation: shine 1.5s infinite;
}

@-webkit-keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

.uiux-list li {
    margin-bottom: 0.5rem;
    text-align: left;
}

.ai-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align everything centrally */
}

.ai-title {
    text-align: center;
    margin-bottom: 30px;
    width: 50%;
}

.ai-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;  /* Adjusted to 60% of its parent's width */
    justify-content: center;  /* Horizontally centering the items */
    align-items: center;  /* Vertically centering the items */
    margin: 0 auto;  /* Center the entire .ai-section horizontally within its parent */
}

.ai-step {
    width: calc(40% - 10px);  /* Adjusted width of each step */
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding: 20px;
    margin: 10px;  /* Even margin around each step for consistent spacing */
}

.ai-step-icon {
    background-color: #007BFF;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.ai-step h2 {
    text-align: left;
}

.ai-step p {
    text-align: left;
}

.ai-section-title {
    font-size: 1.4rem;
    margin-bottom: 20px;
    text-align: left; /* This ensures the text alignment is to the left */
    color: #fff;  /* For a white color title */
    width: 50%;  /* Ensures it takes up the full width */
    margin-top: 60px; 
}

@media only screen and (max-width: 768px) {
    .uiux-content {
        font-size: 1rem;  /* Adjust font size for mobile view */
        margin-top: 10px; /* Adjust top margin for mobile view */
    }
    .ai-section-title{
        margin-top: 10px; 
    }
}


.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem;
}

.contact-title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
}

.contact-title::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    pointer-events: none;
    -webkit-animation: shine 1.5s infinite;
            animation: shine 1.5s infinite;
}

.contact-content {
    font-size: 1.25rem;
    max-width: 800px;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.input-group {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.input-group label {
    font-size: 1rem;
    font-weight: bold;
}

.input-group input, .input-group textarea {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid white;
    background-color: rgba(255,255,255,0.1);
    color: white;
    border-radius: 5px;
    resize: none;
}

.submit-btn {
    text-align: center;
}

.submit-btn button {
    padding: 0.75rem 1.5rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-btn button:hover {
    background-color: rgba(255,255,255,0.8);
}

@-webkit-keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

/* src/pages/services.css */

.uiux-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem;
}

.uiux-title {
    margin-top: -40px;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: -webkit-linear-gradient(white, white, white, rgba(255,255,255,0.4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
}

.uiux-title::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    pointer-events: none;
    -webkit-animation: shine 1.5s infinite;
            animation: shine 1.5s infinite;
}

@-webkit-keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

@keyframes shine {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: 100%; }
}

.service-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: white; 
    text-decoration: none; /* remove the default underline from the link */
}

.uiux-content {
    font-size: 1.25rem;
    max-width: 800px;
    text-align: center;
    margin-bottom: 2rem;
}

.uiux-content p {
    margin: 1rem 0;
}

